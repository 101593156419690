.App {
  background-color: #E2E8F0;
  min-height: 100vh;
  padding-bottom: 100px;
}

.link {
  text-decoration: none;
  color: black;
}

.active {
  color: rgb(224, 58, 7);
  font-weight: 500;
}

.containerClass {
  background: red;
  width: 1px;
}


.adminSideNavActive .menuBtn {
  background: #F6AD55;
    transition: 0.5s;
}
.adminSideNavActive .menuIcon {
background-color: white;
color:#F6AD55;
transition: 0.5s;
}

.adminSideNavActive .menuTitle {
  color: black;
}
